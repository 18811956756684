import * as React from "react";
import * as LabelPrimitive from "@radix-ui/react-label";
import { Slot } from "@radix-ui/react-slot";
import {
  Controller,
  ControllerProps,
  FieldPath,
  FieldValues,
  FormProvider,
  useFormContext,
  SubmitHandler,
  UseFormReturn,
  Path,
  ControllerRenderProps,
} from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import { mergeClassName } from "@/common/utils/helpers";
import { Label } from "./elements/label";

type FormFieldContextValue<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = {
  name: TName;
};

export const FormFieldContext = React.createContext<FormFieldContextValue>(
  {} as FormFieldContextValue
);

const FormField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  ...props
}: ControllerProps<TFieldValues, TName>) => {
  return (
    <FormFieldContext.Provider value={{ name: props.name }}>
      <Controller {...props} />
    </FormFieldContext.Provider>
  );
};

export const useFormField = () => {
  const fieldContext = React.useContext(FormFieldContext);
  const itemContext = React.useContext(FormItemContext);
  const formContextInfo = useFormContext();

  const fieldState = formContextInfo?.getFieldState(
    fieldContext.name,
    formContextInfo?.formState
  );

  if (!fieldContext) {
    throw new Error("useFormField should be used within <FormField>");
  }

  const { id } = itemContext;

  return {
    id,
    name: fieldContext.name,
    formItemId: `${id}-form-item`,
    formDescriptionId: `${id}-form-item-description`,
    formMessageId: `${id}-form-item-message`,
    ...formContextInfo?.formState,
    ...fieldState,
  };
};

type FormItemContextValue = {
  id: string;
};

export const FormItemContext = React.createContext<FormItemContextValue>(
  {} as FormItemContextValue
);

const FormFieldItem = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  const id = React.useId();

  return (
    <FormItemContext.Provider value={{ id }}>
      <div
        ref={ref}
        className={mergeClassName("w-full space-y-2", className)}
        {...props}
      />
    </FormItemContext.Provider>
  );
});
FormFieldItem.displayName = "FormFieldItem";

const FormLabel = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & {
    required?: boolean;
  }
>(({ className, required, ...props }, ref) => {
  const { formItemId } = useFormField();

  return (
    <Label
      ref={ref}
      className={mergeClassName(
        "circularStd w-fit",
        // error && "text-custom-error",
        required &&
          "relative after:absolute after:left-full after:ml-0.5 after:text-red-500 after:content-['*']",
        className
      )}
      htmlFor={formItemId}
      {...props}
    />
  );
});
FormLabel.displayName = "FormLabel";

const FormControl = React.forwardRef<
  React.ElementRef<typeof Slot>,
  React.ComponentPropsWithoutRef<typeof Slot>
>(({ ...props }, ref) => {
  const { error, formItemId, formDescriptionId, formMessageId } =
    useFormField();

  return (
    <Slot
      ref={ref}
      id={formItemId}
      aria-describedby={
        !error
          ? `${formDescriptionId}`
          : `${formDescriptionId} ${formMessageId}`
      }
      aria-invalid={!!error}
      {...props}
    />
  );
});
FormControl.displayName = "FormControl";

const FormDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => {
  const { formDescriptionId } = useFormField();

  return (
    <p
      ref={ref}
      id={formDescriptionId}
      className={mergeClassName("text-sm ", className)}
      {...props}
    />
  );
});
FormDescription.displayName = "FormDescription";

const FormMessage = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, children, ...props }, ref) => {
  const { error, formMessageId } = useFormField();
  const body = error ? String(error?.message) : children;

  if (!body) {
    return null;
  }

  return (
    <p
      ref={ref}
      id={formMessageId}
      className={mergeClassName("text-xs text-red-10", className)}
      {...props}
    >
      {body}
    </p>
  );
});
FormMessage.displayName = "FormMessage";

interface FormProps<T extends FieldValues> {
  onSubmit?: SubmitHandler<T>;
  form: UseFormReturn<T>;
  className?: string;
  children: React.ReactNode;
  onRest?: () => void;
  stopPropagation?: boolean;
}

export const Form = <T extends FieldValues>({
  onSubmit,
  children,
  className,
  form,
  onRest,
  stopPropagation,
}: FormProps<T>) => {
  const resetHandler = () => {
    form.reset();
    if (onRest) {
      onRest();
    }
  };

  const submitHandler = (e: React.BaseSyntheticEvent) => {
    if (stopPropagation) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (onSubmit) {
      form.handleSubmit(onSubmit)(e);
    }
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={submitHandler}
        onReset={resetHandler}
        className={className}
      >
        {children}
        <DevTool control={form.control} />
      </form>
    </FormProvider>
  );
};

type FormItemClassNameType = "item" | "label";

interface FormItemProps<T extends FieldValues> {
  name: Path<T>;
  render: (field: ControllerRenderProps<T, Path<T>>) => React.ReactNode;
  label?: string | React.ReactNode;
  classNames?: Partial<Record<FormItemClassNameType, string>>;
  required?: boolean;
}

export const FormItem = <T extends FieldValues>({
  name,
  render,
  label,
  classNames,
  required,
}: FormItemProps<T>) => {
  const { control } = useFormContext<T>();
  return (
    <FormField<T>
      control={control}
      name={name}
      render={({ field }) => (
        <FormFieldItem className={classNames?.item}>
          {label && typeof label === "string" ? (
            <FormLabel
              className={mergeClassName(classNames?.label)}
              required={required}
            >
              {label}
            </FormLabel>
          ) : null}
          {label && typeof label !== "string" ? label : null}
          <FormControl>{render(field)}</FormControl>
          <FormMessage />
        </FormFieldItem>
      )}
    />
  );
};
