const routes = {
  nonprotected: {
    welcome: "/",
    login: "/auth/login",
    register: "/auth/register",
    resetpassword: "/auth/resetpassword",
    forgotpassword: "/auth/forgotpassword",
    verified: "/auth/verified",
    verified_invite: "/auth/verified?member_invite=true",
    enter_otp: "/auth/enter-otp",
    verify_with_2fa: "/auth/2fa/verify",
    new_password: "/auth/new_password",
    reset_successful: "/auth/reset_successful",
    error_page: "*",
  },
  protected: {
    welcome: "/dashboard",
    dashboard: "/dashboard",
    applications: {
      index: "/apps",
      list: "/apps/all",
      create_new_app: {
        create: "/apps/create-app",
        checkout: "/apps/create-app/checkout",
        paymentsuccess: "/apps/create-app/success",
        update: {
          index: "/apps/create-app/:appid",
          link: (appid: string) => `/apps/create-app/${appid}`,
        },
      },
      app: {
        link: (appid: string) => `/app/${appid}`,
        index: "/app",
        wallet: {
          index: "/app/:appid/wallet/",
          link: (appid: string) => `/app/${appid}/wallet/`,
          fund: {
            nav: "/app/wallet/fundwallet/bank",
            index: "/app/wallet/fundwallet/bank/:appid",
            link: (appid: string) => `/app/wallet/fundwallet/bank/${appid}`,
            bank: {
              index: "/app/:appid/wallet/:txnid/fundwallet/bank",
              link: (appid: string, txnid: string) =>
                `/app/${appid}/wallet/${txnid}/fundwallet/bank`,
            },
            crypto: {
              nav: "/app/wallet/fundwallet/crypto",
              index: "/app/wallet/fundwallet/crypto/:appid",
              link: (appid: string) => `/app/wallet/fundwallet/crypto/${appid}`,
            },
            payment_processing: {
              index: "/app/:appid/wallet/fundwallet/payment-processing",
              link: (appid: string, stage: number) =>
                `/app/${appid}/wallet/fundwallet/payment-processing?stage=${stage}`,
            },
            awaiting_confirmation: {
              index:
                "/app/:appid/wallet/:txnid/fundwallet/awaiting-confirmation",
              link: (appid: string, txnid: string) =>
                `/app/${appid}/wallet/${txnid}/fundwallet/awaiting-confirmation`,
            },
            success: {
              index: "/app/:appid/wallet/fundwallet/success",
              link: (appid: string) =>
                `/app/${appid}/wallet/fundwallet/success`,
            },
          },
          withdrawl: {
            nav: "/app/wallet/withdraw/bank",
            index: "/app/wallet/withdraw/bank/:appid",
            link: (appid: string) => `/app/wallet/withdraw/bank/${appid}`,
            authorize_bank: {
              index: "/app/:appid/wallet/withdraw/confirm-bank",
              link: (appid: string) =>
                `/app/${appid}/wallet/withdraw/confirm-bank`,
            },
            authorize_crypto: {
              nav: "/app/wallet/withdraw/confirm-crypto",
              index: "/app/wallet/withdraw/confirm-crypto/:appid",
              link: (appid: string) =>
                `/app/wallet/withdraw/confirm-crypto/${appid}`,
            },
            success: {
              index: "/app/:appid/wallet/withdraw/success",
              link: (appid: string) => `/app/${appid}/wallet/withdraw/success`,
            },
            threshold: {
              index: "/app/:appid/wallet/withdrawal-threshold",
              link: (appid: string) =>
                `/app/${appid}/wallet/withdrawal-threshold`,
            },
          },
          settings: {
            // index: "/app/:appid/wallet/settings",
            // link: (appid: string) => `/app/${appid}/wallet/settings`,
            withdrawal_details: {
              crypto: {
                index: "/app/:appid/wallet/settings/crypto",
                link: (appid: string) => `/app/${appid}/wallet/settings/crypto`,
                add_crypto: {
                  index: "/app/:appid/wallet/settings/add-wallet-address",
                  link: (appid: string) =>
                    `/app/${appid}/wallet/settings/add-wallet-address`,
                },
              },
              bank: {
                index: "/app/:appid/wallet/settings/bank",
                link: (appid: string) => `/app/${appid}/wallet/settings/bank`,
                select_bank_type: {
                  index: "/app/:appid/wallet/settings/bank/type",
                  link: (appid: string) =>
                    `/app/${appid}/wallet/settings/bank/type`,
                },
                add_bank: {
                  index: "/app/:appid/wallet/settings/add-bank-account",
                  link: (appid: string) =>
                    `/app/${appid}/wallet/settings/add-bank-account`,
                },
              },
              confirm_action: {
                index: "/app/:appid/wallet/settings/confirm-action/:accid",
                link: (appid: string, accid: string) =>
                  `/app/${appid}/wallet/settings/confirm-action/${accid}`,
              },
              success: {
                index: "/app/:appid/wallet/settings/success",
                link: (appid: string) =>
                  `/app/${appid}/wallet/settings/success`,
              },
            },
          },
        },
        vas_services: {
          utility: {
            index: "/app/:appid/vas-services/utility",
            link: (appid: string) => `/app/${appid}/vas-services/utility`,
            threshold: {
              index: "/app/:appid/vas-services/utility/withdrawal-threshold",
              link: (appid: string) =>
                `/app/${appid}/vas-services/utility/withdrawal-threshold`,
            },
            fund: {
              index: "/app/:appid/vas-services/utility/fund",
              link: (appid: string) =>
                `/app/${appid}/vas-services/utility/fund`,
            },
            withdraw: {
              index: "/app/:appid/vas-services/utility/withdraw",
              link: (appid: string) =>
                `/app/${appid}/vas-services/utility/withdraw`,
            },

            history: {
              index: "/app/:appid/vas-services/utility/history",
              link: (appid: string) =>
                `/app/${appid}/vas-services/utility/history`,
            },
            records: {
              index: "/app/:appid/vas-services/utility/records",
              link: (appid: string) =>
                `/app/${appid}/vas-services/utility/records`,
            },
          },
        },
        transactions: {
          index: "/app/:appid/transactions/",
          link: (appid: string) => `/app/${appid}/transactions/`,
          transaction_information: {
            index: "/app/:appid/transactions/transaction/:txnid",
            link: (appid: string, txnid: string) =>
              `/app/${appid}/transactions/transaction/${txnid}`,
          },
          report_transaction: {
            index: "/app/:appid/transactions/transaction/:txnid/report",
            link: (appid: string, txnid: string) =>
              `/app/${appid}/transactions/transaction/${txnid}/report`,
          },
          outgoingTx: {
            index: "/app/:appid/transactions/outgoing-transactions",
            link: (appid: string) =>
              `/app/${appid}/transactions/outgoing-transactions`,
          },
          incomingTx: {
            index: "/app/:appid/transactions/incoming-settlements",
            link: (appid: string) =>
              `/app/${appid}/transactions/incoming-settlements`,
          },
        },
        risk_control: {
          policy: {
            index: "/app/:appid/risk-control/policy",
            link: (appid: string) => `/app/${appid}/risk-control/policy`,
            create_policy: {
              index: "/app/:appid/risk-control/policy/create-policy",
              link: (appid: string) =>
                `/app/${appid}/risk-control/policy/create-policy`,
            },
            update_policy: {
              index: "/app/:appid/risk-control/policy/update-policy/:policyid",
              link: (appid: string, policyid: string) =>
                `/app/${appid}/risk-control/policy/update-policy/${policyid}`,
            },
            view_policy: {
              index: "/app/:appid/risk-control/policy/view-policy/:policyid",
              link: (appid: string, policyid: string) =>
                `/app/${appid}/risk-control/policy/view-policy/${policyid}`,
            },
          },
          categories: {
            index: "/app/:appid/risk-control/category",
            link: (appid: string) => `/app/${appid}/risk-control/category`,
            create_category: {
              index: "/app/:appid/risk-control/create-category",
              link: (appid: string) =>
                `/app/${appid}/risk-control/create-category`,
            },
            update_category: {
              index: "/app/:appid/risk-control/update-category/:catid",
              link: (appid: string, catid: string) =>
                `/app/${appid}/risk-control/update-category/${catid}`,
            },
            block_user: {
              index: "/app/:appid/risk-control/:userid/confirm-action",
              link: (appid: string, userid: string) =>
                `/app/${appid}/risk-control/${userid}/confirm-action`,
            },
          },
          risks: {
            risk_record: {
              index: "/app/:appid/risk-control/config/:policyid/risk-record",
              link: (aid: string, pid: string, pname?: string) =>
                `/app/${aid}/risk-control/config/${pid}/risk-record?policy=${pname}`,
            },
            notification_config: {
              index: "/app/:appid/risk-control/config/:policyid/notification",
              link: (aid: string, pid: string, pname?: string) =>
                `/app/${aid}/risk-control/config/${pid}/notification?policy=${pname}`,
              add: {
                index:
                  "/app/:appid/risk-control/config/:policyid/notification/add",
                link: (aid: string, pid: string) =>
                  `/app/${aid}/risk-control/config/${pid}/notification/add`,
              },
            },
          },
        },
        api_settings: {
          index: "/app/:appid/api-settings",
          link: (appid: string) => `/app/${appid}/api-settings`,
          shown_api_setting: {
            index: "/app/:appid/api-settings/s/confirm",
            link: (appid: string) => `/app/${appid}/api-settings/s/confirm`,
          },
          regen_api_keys: {
            index: "/app/:appid/api-settings/r/confirm",
            link: (appid: string) => `/app/${appid}/api-settings/r/confirm`,
          },
          api_keys: {
            index: "/app/:appid/api-settings/api-keys",
            link: (appid: string) => `/app/${appid}/api-settings/api-keys`,
          },
          prod_webhooks: {
            index: "/app/:appid/api-settings/prod-webhooks",
            link: (appid: string) => `/app/${appid}/api-settings/prod-webhooks`,
          },
          test_webhooks: {
            index: "/app/:appid/api-settings/test-webhooks",
            link: (appid: string) => `/app/${appid}/api-settings/test-webhooks`,
          },
          // download_api_keys: {
          //   index: "/app/:appid/api-settings/d/confirm",
          //   link: (appid: string) => `/app/${appid}/api-settings/d/confirm`,
          // },
        },
        checkout_settings: {
          index: "/app/:appid/checkout-settings",
          link: (appid: string) => `/app/${appid}/checkout-settings`,
          services: {
            index: "/app/:appid/checkout-settings/services",
            link: (appid: string) => `/app/${appid}/checkout-settings/services`,
          },
          branding: {
            index: "/app/:appid/checkout-settings/branding",
            link: (appid: string) => `/app/${appid}/checkout-settings/branding`,
          },
          pricing: {
            index: "/app/:appid/checkout-settings/pricing",
            link: (appid: string) => `/app/${appid}/checkout-settings/pricing`,
          },
        },
        users: {
          index: "/app/:appid/users",
          link: (appid: string) => `/app/${appid}/users`,
          block_user: {
            index: "/app/:appid/users/:userid/confirm-action",
            link: (appid: string, userid: string) =>
              `/app/${appid}/users/${userid}/confirm-action`,
          },
        },
        include: {
          index: "/app/:appid/include",
          link: (appid: string) => `/app/${appid}/include`,
          toggle_include: {
            on: {
              index: "/app/:appid/include/i/n/confirm",
              link: (appid: string) => `/app/${appid}/include/i/n/confirm`,
            },
            off: {
              index: "/app/:appid/include/i/f/confirm",
              link: (appid: string) => `/app/${appid}/include/i/f/confirm`,
            },
          },
          wallet: {
            index: "/app/:appid/include/money",
            link: (appid: string) => `/app/${appid}/include/money`,
          },
          url: {
            index: "/app/:appid/include/url",
            link: (appid: string) => `/app/${appid}/include/url`,
          },
          pricing: {
            index: "/app/:appid/include/pricing",
            link: (appid: string) => `/app/${appid}/include/pricing`,
          },
          config: {
            index: "/app/:appid/include/config",
            link: (appid: string) => `/app/${appid}/include/config`,
          },
          template: {
            index: "/app/:appid/include/template",
            link: (appid: string) => `/app/${appid}/include/template`,
            view_template: {
              index: "/app/:appid/include/template/:templateType",
              link: (appid: string, templateType: string) =>
                `/app/${appid}/include/template/${templateType}`,
            },
          },
        },
        analytics: {
          index: "/app/:appid/analytics",
          link: (appid: string) => `/app/${appid}/analytics`,
        },
        virtual_cards: {
          index: "/app/:appid/virtual-cards",
          link: (appid: string) => `/app/${appid}/virtual-cards`,
          wallet_record: {
            index: "/app/:appid/virtual-cards/wallet-record",
            link: (appid: string) =>
              `/app/${appid}/virtual-cards/wallet-record`,
          },
          cards_record: {
            index: "/app/:appid/virtual-cards/cards-record",
            link: (appid: string) => `/app/${appid}/virtual-cards/cards-record`,
          },
          transactions_record: {
            index: "/app/:appid/virtual-cards/transactions-record",
            link: (appid: string) =>
              `/app/${appid}/virtual-cards/transactions-record`,
          },
          pricing: {
            index: "/app/:appid/virtual-cards/pricing",
            link: (appid: string) => `/app/${appid}/virtual-cards/pricing`,
          },
        },
      },
    },
    white_label: {
      plans: "/apps/white-label/plans",
      active_subscription: "/apps/white-label/active-subscription",
      merchants: "/apps/white-label/merchants",
      modals: {
        create_merchant: "/apps/white-label/merchants/form",
        purchase_plan: "/apps/white-label/plans/purchase",
      },
    },
    compliance: {
      index: "/compliance",
      business_document: {
        index: "/compliance/business-document",
        get_started: "/compliance/business-document/create",
        update_doc: {
          index: `/compliance/business-document/update`,
          link: (params: string) =>
            `/compliance/business-document/update?type=${params}`,
        },
      },
      shareholders: {
        index: "/compliance/shareholders",
        link: (from: string) => `/compliance/shareholders?from=${from}`,
        add: "/compliance/shareholders/add",
        remove: {
          index: "/compliance/shareholders/:shareid/remove",
          link: (shareid: string) =>
            `/compliance/shareholders/${shareid}/remove`,
        },
        kyc: "/compliance/shareholders/kyc",
      },
      bussiness_profile: "/compliance/business-profile",
    },
    onboarding: {
      index: "/onboarding",
      terms: {
        index: "/onboarding/terms",
        agreement: "/onboarding/terms/agreement-info",
        authorize: "/onboarding/terms/authorize",
        authorize_with_link: "/onboarding/terms/authorize?external-link=true",
      },
      business_document: {
        index: "/onboarding/business-document",
        get_started: "/onboarding/business-document/create",
        update_doc: {
          index: `/onboarding/business-document/update`,
          link: (params: string) =>
            `/onboarding/business-document/update?type=${params}`,
        },
      },
      shareholders: {
        index: "/onboarding/shareholders",
        add: "/onboarding/shareholders/add",
        kyc: "/onboarding/shareholders/kyc",
        remove: {
          index: "/onboarding/shareholders/:shareid/remove",
          link: (shareid: string) =>
            `/onboarding/shareholders/${shareid}/remove`,
        },
      },
      bussiness_profile: "/onboarding/business-profile",
    },
    preview_app: "/preview",
    settings: {
      index: "/settings",
      security: "/settings/security",
      business_profile: "/settings/profile",
      transaction_country: "/settings/transaction-country",
      password_confrim: "/p/confirm",
      two_factor_confirm_password_update: "/fa/confirm",
      two_factor_toggle: "/fa/tg/confirm",
      two_factor_prompt: "/fa/pmt/confirm",
      activity_log: "/settings/activity_log",
      logout: "/logout",
    },
    user: {
      index: "/user",
      add_user: "/user/create",
      update_user: {
        index: "/user/:uid/update",
        link: (uid: string) => `/user/${uid}/update`,
      },
      suspend_user: {
        index: "/user/:uid/action",
        link: (uid: string) => `/user/${uid}/action`,
      },
      rescind_invite: {
        index: "/user/:uid/remove",
        link: (uid: string) => `/user/${uid}/remove`,
      },
    },
  },
};

export default routes;
