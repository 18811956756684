import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { transferFormType, resolver } from "./schema";
import { defaultValues, optionsHandler, transferFormFeilds } from "./helper";
import { Form, FormItem } from "@/common/components/Form";
import { Input } from "@/common/components/Form/elements/Input";
import Select from "@/common/components/Form/elements/Select";
import CustomButton from "@/common/components/CustomButton";

function TransferForm() {
  const form = useForm<transferFormType>({
    resolver,
    defaultValues,
  });

  const pricing_type = form.watch("pricing_type");
  const pricing_condition = form.watch("pricing_condition");
  const hideFields: Array<keyof transferFormType> = ["floor_cap", "upper_cap"];

  useEffect(() => {
    if (pricing_condition === "Fee with No Cap") {
      const condition = {
        shouldDirty: false,
        shouldTouch: false,
      };
      hideFields.forEach((key) => {
        form.setValue(key, "0.00", condition);
      });
    }
  }, [pricing_condition]); //eslint-disable-line

  const submitHandler = (data: transferFormType) => {
    console.log(data);
  };

  return (
    <Form<transferFormType>
      {...form}
      form={form}
      onSubmit={submitHandler}
      className="grid grid-cols-2 gap-x-7 gap-y-8 lg:pr-7"
    >
      {transferFormFeilds
        .filter((field) => {
          if (
            pricing_condition === "Fee with No Cap" &&
            hideFields.includes(field.name)
          ) {
            return false;
          }
          return true;
        })
        .map((field) => {
          if (field.type === "select") {
            const options = optionsHandler(field.name);
            return (
              <FormItem
                key={field.name}
                name={field.name}
                label={field.label}
                required={field?.rules?.required}
                classNames={{
                  label: "text-sm leading-6 text-[#090914] mb-2.5",
                }}
                render={(otherProps) => (
                  <>
                    <Select
                      placeholder={field.placeHolder}
                      {...otherProps}
                      data={options}
                    />
                    <OtherInfo data={field?.otherInfo} />
                  </>
                )}
              />
            );
          }
          return (
            <FormItem
              key={field.name}
              name={field.name}
              label={field.label}
              required={field?.rules?.required}
              classNames={{
                label: "text-sm leading-6 text-[#090914] mb-2.5",
              }}
              render={(otherProps) => (
                <>
                  <Input
                    placeholder={field.placeHolder}
                    {...otherProps}
                    suffix={
                      pricing_type === "Flat Rate" && field?.suffixIcon
                        ? "$"
                        : field.suffixIcon
                    }
                  />
                  <OtherInfo data={field?.otherInfo} />
                </>
              )}
            />
          );
        })}
      <CustomButton className="col-start-1 justify-self-start">
        Submit
      </CustomButton>
    </Form>
  );
}

const OtherInfo = ({ data }: { data?: string }) => {
  return (
    <>
      {data ? (
        <p className="text-sm font-medium text-[#090914]">{data}</p>
      ) : null}
    </>
  );
};

export default TransferForm;
