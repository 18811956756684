import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { amountValidation } from "@/common/utils/helpers";

const transferFormSchema = z.object({
  pricing_type: z.string({ message: "Pricing type is required" }),
  pricing_condition: z.string({ message: "Pricing condition is required" }),
  upper_cap: amountValidation("Upper Cap").optional(),
  floor_cap: amountValidation("Floor Cap").optional(),
  finswich_fee: amountValidation("Finswich Fee"),
  markup_fee: amountValidation("Your Mark-up Fee"),
  service_fee: amountValidation("Service Fee"),
});

export const resolver = zodResolver(transferFormSchema);

export type transferFormType = z.infer<typeof transferFormSchema>;
