import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { amountValidation } from "@/common/utils/helpers";

const kycFormSchema = z.object({
  charge_kyc: z.string({ message: "Charge KYC is required" }),
  kyc_pricing: amountValidation("KYC Pricing"),
  split: amountValidation("Split Charges").optional(),
  charge_cycle: amountValidation("Charging cycle").optional(),
  narration: z.string({ message: "Narration is required" }).optional(),
});

export const resolver = zodResolver(kycFormSchema);

export type kycFormType = z.infer<typeof kycFormSchema>;
