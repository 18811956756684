import { FieldTypes } from "@/common/components/Form/type";
import { cardFormType } from "./schema";

export const transferFormFeilds: FieldTypes<cardFormType>[] = [
  {
    name: "create_card",
    label: "Create Card",
    otherInfo: "Cost of creating card",
    prefixIcon: "$",
  },
  {
    name: "card_withdrawal",
    label: "Card Withdrawal",
    otherInfo: "Cost of withdrawing from card",
    prefixIcon: "$",
  },
  {
    name: "card_funding",
    label: "Card Funding",
    otherInfo: "Amount to charge on card funding",
    prefixIcon: "$",
  },
  {
    name: "card_maintenance",
    label: "Card Maintenance",
    otherInfo: "Monthly card maintenance fee",
    prefixIcon: "$",
  },
  {
    name: "card_transaction_fee_type",
    label: "Card Transaction",
    otherInfo: "Select fee type (flat or percentile)",
    type: "select",
  },
  {
    name: "card_transaction",
    label: "Card Transaction",
    otherInfo: "Amount to charge on successful txn",
    suffixIcon: "%",
  },
  {
    name: "penalty",
    label: "Insufficient Balance Penalty",
    otherInfo:
      "This will be charged as penalty for insufficient balance more than 2 times",
    suffixIcon: "%",
  },
];

export const defaultValues: cardFormType = {
  create_card: "",
  card_withdrawal: "",
  card_funding: "",
  card_maintenance: "",
  card_transaction_fee_type: "Percentile",
  card_transaction: "0.00",
  penalty: "",
};

const transactionTypeOptions = [
  { label: "Percentile", value: "Percentile" },
  { label: "Flat", value: "Flat" },
];

export const optionsHandler = (name: string) => {
  if (name === "card_transaction_fee_type") {
    return transactionTypeOptions;
  }

  return [];
};
