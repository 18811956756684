import { FieldTypes } from "@/common/components/Form/type";
import { transferFormType } from "./schema";

export const transferFormFeilds: FieldTypes<transferFormType>[] = [
  { name: "pricing_type", label: "Select Pricing Type", type: "select" },
  {
    name: "pricing_condition",
    label: "Select Pricing Condition",
    type: "select",
  },
  {
    name: "upper_cap",
    label: "Upper Cap",
    otherInfo:
      "Where markup is more than “Upper cap”, Upper cap fee will be charged as flat rate",
    suffixIcon: "$",
  },
  {
    name: "floor_cap",
    label: "Floor Cap",
    otherInfo:
      "Where markup is lesser than “floor cap”, Floor cap fee will be charged as flat rate",
    suffixIcon: "$",
  },
  {
    name: "finswich_fee",
    label: "Finswich Fee",
    otherInfo: "What you will be charged yby Finswich",
  },
  { name: "markup_fee", label: "Your Mark-up Fee", suffixIcon: "%" },
  {
    name: "service_fee",
    label: "Service Fee",
    suffixIcon: "%",
    otherInfo: "This will be charged additional to 'Mark-up Fee'",
  },
];

export const defaultValues: transferFormType = {
  pricing_type: "Percentage Rate",
  pricing_condition: "Fee with Cap",
  upper_cap: "0.00",
  floor_cap: "0.00",
  finswich_fee: "",
  markup_fee: "",
  service_fee: "",
};

const pricingTypeOptions = [
  { label: "Percentage Rate", value: "Percentage Rate" },
  { label: "Flat Rate", value: "Flat Rate" },
];
const pricingConditonOptions = [
  { label: "Fee with Cap", value: "Fee with Cap" },
  { label: "Fee with No Cap", value: "Fee with No Cap" },
];

export const optionsHandler = (name: string) => {
  if (name === "pricing_type") {
    return pricingTypeOptions;
  }
  if (name === "pricing_condition") {
    return pricingConditonOptions;
  }
  return [];
};
