import { TokenDataType } from "@/module/auth/type";
import dayjs from "dayjs";
import jwtDecode from "jwt-decode";

export const decodeToken = (token: string) => {
  const decodedToken = jwtDecode<TokenDataType>(token)?.exp || 0;
  return diffrenceFromNowToExpirationInSeconds(decodedToken);
};

export function diffrenceFromNowToExpirationInSeconds(epoch: number): number {
  const now = dayjs();
  const expire = dayjs(epoch * 1000);

  return expire.diff(now, "seconds");
}

export const isNotGreaterThanToday = new Date(
  dayjs().endOf("day").toISOString()
);

export const formatExportDate = (date?: Date | null) => {
  if (!date) return;
  return dayjs(date).format("YYYY-MM-DD");
};
