import React from "react";
import { useForm } from "react-hook-form";
import { cardFormType, resolver } from "./schema";
import { defaultValues, optionsHandler, transferFormFeilds } from "./helper";
import { Form, FormItem } from "@/common/components/Form";
import { Input } from "@/common/components/Form/elements/Input";
import Select from "@/common/components/Form/elements/Select";
import CustomButton from "@/common/components/CustomButton";

function CardForm() {
  const form = useForm<cardFormType>({
    resolver,
    defaultValues,
  });

  const card_transaction_fee_type = form.watch("card_transaction_fee_type");

  const submitHandler = (data: cardFormType) => {
    console.log(data);
  };

  return (
    <Form<cardFormType>
      {...form}
      form={form}
      onSubmit={submitHandler}
      className="grid grid-cols-2 gap-x-7 gap-y-8 lg:pr-7"
    >
      {transferFormFeilds.map((field) => {
        if (field.type === "select") {
          const options = optionsHandler(field.name);
          return (
            <FormItem
              key={field.name}
              name={field.name}
              label={field.label}
              required={field?.rules?.required}
              classNames={{
                label: "text-sm leading-6 text-[#090914] mb-2.5",
              }}
              render={(otherProps) => (
                <>
                  <Select
                    placeholder={field.placeHolder}
                    {...otherProps}
                    data={options}
                  />
                  <OtherInfo data={field?.otherInfo} />
                </>
              )}
            />
          );
        }
        return (
          <FormItem
            key={field.name}
            name={field.name}
            label={field.label}
            required={field?.rules?.required}
            classNames={{
              label: "text-sm leading-6 text-[#090914] mb-2.5",
            }}
            render={(otherProps) => (
              <>
                <Input
                  placeholder={field?.placeHolder}
                  {...otherProps}
                  prefix={field?.prefixIcon}
                  suffix={
                    card_transaction_fee_type === "Flat" && field?.suffixIcon
                      ? "$"
                      : field?.suffixIcon
                  }
                />
                <OtherInfo data={field?.otherInfo} />
              </>
            )}
          />
        );
      })}

      <CustomButton className="col-start-1 justify-self-start">
        Submit
      </CustomButton>
    </Form>
  );
}

const OtherInfo = ({ data }: { data?: string }) => {
  return (
    <>
      {data ? (
        <p className="text-sm font-medium italic text-[#090914]">{data}</p>
      ) : null}
    </>
  );
};

export default CardForm;
