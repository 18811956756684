import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { amountValidation } from "@/common/utils/helpers";

const cardFormSchema = z.object({
  create_card: amountValidation("Create card"),
  card_withdrawal: amountValidation("Create Withdrawal"),
  card_funding: amountValidation("Create Funding"),
  card_maintenance: amountValidation("Create Maintenance"),

  card_transaction_fee_type: z.string({
    message: "Select fee type (flat or percentile)",
  }),
  card_transaction: amountValidation("Create Transaction"),
  penalty: amountValidation("Insufficient Balance Penalty"),
});

export const resolver = zodResolver(cardFormSchema);

export type cardFormType = z.infer<typeof cardFormSchema>;
