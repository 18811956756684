import { FieldTypes } from "@/common/components/Form/type";
import { kycFormType } from "./schema";

export const transferFormFeilds: FieldTypes<kycFormType>[] = [
  {
    name: "charge_kyc",
    label: "Charge KYC",
    type: "select",
    otherInfo: "Select if charge applies or not",
  },
  {
    name: "kyc_pricing",
    label: "KYC Pricing",
    otherInfo: "Cost to charge user for  KYC",
    prefixIcon: "$",
  },
  {
    name: "split",
    label: "Split Charges into trickles",
    otherInfo: "How many times you want to charge user",
    suffixIcon: "Times",
  },
  {
    name: "charge_cycle",
    label: "Charging cycle",
    otherInfo: "Charges will occur on every cycle",
    suffixIcon: "DAYS",
  },
  {
    name: "narration",
    label: "Narration",
    placeHolder: "Describe narration for debit",
  },
];

export const defaultValues: kycFormType = {
  charge_kyc: "Apply Charge",
  kyc_pricing: "",
  split: "",
  charge_cycle: "",
  narration: "",
};

const chargeOptions = [
  { label: "Apply Charge", value: "Apply Charge" },
  { label: "No Charges", value: "No Charges" },
];

export const optionsHandler = (name: string) => {
  if (name === "charge_kyc") {
    return chargeOptions;
  }

  return [];
};
