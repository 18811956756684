import SideModal from "@/common/components/CustomModal/SideModal";
import ModalTitleHeader from "@/common/components/ModalTitleHeader";
import React from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import TransferForm from "./components/TransferForm";
import CardForm from "./components/CardForm";
import KycForm from "./components/KycForm";
import CustomButton from "@/common/components/CustomButton";

const pageTitle: Record<string, Record<"title" | "subTitle", string>> = {
  transfer: {
    title: "Global Transfer Pricing",
    subTitle: "Manage how much your users get charged for global transfers.",
  },
  card: {
    title: "Card Pricing Pricing",
    subTitle: "Manage how much your users get charged for Card Pricing",
  },
  kyc: {
    title: "KYC Pricing",
    subTitle: "Manage how much your users get charged for User’s KYC",
  },
};

export function Pricing() {
  const [params] = useSearchParams();
  const type: string = params.get("type") || "";

  const { appid } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const pathname: string = state?.background?.pathname;

  const backDropHandler = () => {
    if (!appid || !pathname) return;
    navigate(pathname, { replace: true });
  };

  return (
    <SideModal
      className="flex flex-col space-y-9 p-8 md:pt-9 md:pl-15 md:pr-28 md:pb-9"
      onclickBackDrop={backDropHandler}
    >
      <div className="flex items-center justify-between">
        <button onClick={backDropHandler}>
          <span className="text-3xl">&#8592;</span>
        </button>
        <CustomButton className="flex h-auto items-center bg-transparent py-1 text-sm font-bold text-[#1E3F52]">
          View Pricing Catalog <span className="text-3xl">&rarr;</span>
        </CustomButton>
      </div>
      <ModalTitleHeader
        title={`${pageTitle?.[type]?.title || ""}`}
        subtitle={`${pageTitle?.[type]?.subTitle || ""}`}
      />
      {type === "transfer" ? <TransferForm /> : null}
      {type === "card" ? <CardForm /> : null}
      {type === "kyc" ? <KycForm /> : null}
    </SideModal>
  );
}
