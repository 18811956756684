import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { kycFormType, resolver } from "./schema";
import { defaultValues, optionsHandler, transferFormFeilds } from "./helper";
import { Form, FormItem } from "@/common/components/Form";
import { Input } from "@/common/components/Form/elements/Input";
import Select from "@/common/components/Form/elements/Select";
import CustomButton from "@/common/components/CustomButton";

function KycForm() {
  const form = useForm<kycFormType>({
    resolver,
    defaultValues,
  });

  const charge_kyc = form.watch("charge_kyc");
  const filterCondition = charge_kyc === "No Charges";
  const hideFields: Array<keyof kycFormType> = [
    "split",
    "charge_cycle",
    "narration",
  ];

  useEffect(() => {
    if (filterCondition) {
      const condition = {
        shouldDirty: false,
        shouldTouch: false,
      };
      hideFields.forEach((key) => {
        form.setValue(key, undefined, condition);
      });
    }
  }, [charge_kyc]); //eslint-disable-line

  const submitHandler = (data: kycFormType) => {
    console.log(data);
  };

  return (
    <Form<kycFormType>
      {...form}
      form={form}
      onSubmit={submitHandler}
      className="grid grid-cols-2 gap-x-7 gap-y-8 lg:pr-7"
    >
      {transferFormFeilds
        .filter((field) => {
          if (filterCondition && hideFields.includes(field.name)) {
            return false;
          }
          return true;
        })
        .map((field) => {
          if (field.type === "select") {
            const options = optionsHandler(field.name);
            return (
              <FormItem
                key={field.name}
                name={field.name}
                label={field.label}
                required={field?.rules?.required}
                classNames={{
                  label: "text-sm leading-6 text-[#090914] mb-2.5",
                }}
                render={(otherProps) => (
                  <>
                    <Select
                      placeholder={field.placeHolder}
                      {...otherProps}
                      data={options}
                    />
                    <OtherInfo data={field?.otherInfo} />
                  </>
                )}
              />
            );
          }
          return (
            <FormItem
              key={field.name}
              name={field.name}
              label={field.label}
              required={field?.rules?.required}
              classNames={{
                label: "text-sm leading-6 text-[#090914] mb-2.5",
                item: field.name === "narration" ? "col-span-full" : "",
              }}
              render={(otherProps) => (
                <>
                  <Input
                    placeholder={field?.placeHolder}
                    {...otherProps}
                    suffix={field?.suffixIcon}
                    prefix={field?.prefixIcon}
                  />
                  <OtherInfo data={field?.otherInfo} />
                </>
              )}
            />
          );
        })}
      <div className="col-span-full col-start-1 space-y-3 [&_p]:text-[#090914]">
        <p className="text-xl font-medium leading-6">Summary</p>
        <p className="text-sm font-medium [&_span]:font-bold">
          Your user will be charged <span>{`{amount_to_charge}`}</span> every{" "}
          <span>{`{cycle_days}`}</span> for <span>{`{Cycle_times}`}</span> until
          the full <span>{`{KYC_amount}`}</span> is paid
        </p>
      </div>
      <CustomButton className="col-start-1 justify-self-start">
        Submit
      </CustomButton>
    </Form>
  );
}

const OtherInfo = ({ data }: { data?: string }) => {
  return (
    <>
      {data ? (
        <p className="text-sm font-medium italic text-[#090914]">{data}</p>
      ) : null}
    </>
  );
};

export default KycForm;
